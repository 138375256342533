import React from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { Link, graphql } from 'gatsby'

import GreyPageTemplate from '../templates/grey-page'
import ArticleList from '../components/ArticleList'

import { Headline2, Body2 } from '../components/Typography'

const BlueLink = styled(Link)`
  ${Body2}
  font-weight: bold;
  text-decoration: none;
`

const Title = styled.h2`
  ${Headline2};
  color: var(--terminal-500);
  margin-block-start: 0;
  margin-block-end: 0;
  ${mq({
    marginBottom: ['80px', '80px', '118px', '118px', '118px'],
  })};
`

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const posts = data.allMarkdownRemark.edges
  const categoryHeader = `${_.capitalize(category)} articles`
  const pageTitle = categoryHeader

  return (
    <GreyPageTemplate title={pageTitle} copyrightYear={'2022'}>
      <BlueLink to="/blog">← Back to all articles</BlueLink>
      <Title>{categoryHeader}</Title>
      <ArticleList posts={posts} />
    </GreyPageTemplate>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/*/*.md" }
        frontmatter: { category: { in: [$category] }, draft: { ne: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
          }
        }
      }
    }
  }
`
